/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Pagination as MuiPagination,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isLib } from '../..';
import IMultiSelectWithCheckBox from '../../components/autoComplete/IMultiSelectWithCheckBox';
import FilterRoot from '../../components/filter/FilterRoot';
import IOSSwitch from '../../components/IOSSwitch';
import LoadingWrap from '../../components/LoadingWrap';
import StyledAutocomplete from '../../components/styled-autocomplete';
import useStyledAutocomplete from '../../components/styled-autocomplete/useStyledAutocomplete';
import StyledDateRangePicker from '../../components/StyledDateRangePicker';
import EditIcon3 from '../../components/svg/EditIcon3';
import {
    companyController,
    locationController,
    regionController,
    tenantController,
    vehicleController,
    vehicleListController,
    whiteListController,
    zoneController,
} from '../../controllers';
import useDbSearch from '../../hooks/useDbSearch';
import { AuthContext, AuthPermission } from '../../layout/AuthLayout';
import { FilterLocation, FilterRequest } from '../../models/filter/BaseFilter';
import { Company } from '../../models/scope/Company';
import { Location } from '../../models/scope/Location';
import { Region } from '../../models/scope/Region';
import Zone from '../../models/scope/Zone';
import { VehicleList, VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { WhiteListFilterResponse } from '../../models/whiteList/WhiteList';
import { TenantList, ZoneList } from '../vehicleList/components/VehicleListItem';
import { searchTypes } from '../vehicleList/model/SearchType';
import { SearchField } from './StartAdornment';
import WhiteListItem from './WhiteListItem';
import { GFlexRow, TitleWithBackButton } from '../../styles/style';
import PopUpWarning from '../../components/PopUpWarning';
import { pushError, pushSuccess } from '../../components/toast';
import LinkTo from '../../components/LinkTo';
import color from '../../config/Colors';
import { SearchParams, VehicleListPageProps } from '../vehicleList';
import { VLLogAction } from '../../models/vehicleListAction/VLLogAction';
import { CheckVehicleListExpired, GetVehicleListDescription } from '../vehicleList/helpers';
import { Shop } from '../../models/scope/Tenant';
import { ScopeType } from '../../models/permit/Scope';
import { ServiceContext } from '../../providers/ServiceProvider';
import { ServiceId } from '../../models/vehicleList/Service';
import AutocompleteNoPagi from '../../components/styled-autocomplete/MyAutocomplete';
import { RecurringType } from '../../models/permit/PermitRecurring';
import { PermitTypeVl } from '../../models/permit/Permit';

const initState: State = {
    page: 1,
    pageSize: 10,
    filter: {
        zones: [],
    },
    search: {
        type: searchTypes[0],
        value: '',
    },
};

export type VehicleListDetailAction = {
    onAddVRN(vehicleList: VehicleList): void;
    onViewVRN(vehicleList: VehicleList): void;
    onEdit(vehicleList: VehicleList): void;
    onBack(vehicleList: VehicleList): void;
    onViewEventLog(vehicleList: VehicleList): string;
};

export type VehicleListDetailsProps = VehicleListPageProps & {
    vehicleList: VehicleListWithZone;
    action?: VehicleListDetailAction;
    onChangeActive(status: number): void;
};

interface UseSearchProps {
    searchFilter: string;
    onChangeText: (text: string) => any;
}
type SearchParamsVLDetails = Pick<
    SearchParams,
    'locationId' | 'search' | 'zoneIds' | 'region' | 'tenantIds' | 'page' | 'companyId' | 'servicesType'
> & {
    permitDate: string | Date;
    VehicleListName: string;
};
export function useSearch(props: UseSearchProps) {
    const [searchValue, setSearchValue] = useState('');
    const [searchFocused, setSearchFocused] = useState(false);

    const handleChangeSearch = useDbSearch({
        fn: props.onChangeText,
    });

    useEffect(() => {
        setSearchValue(props.searchFilter);
    }, [props.searchFilter]);

    return {
        searchValue,
        setSearchValue,
        searchFocused,
        setSearchFocused,
        handleChangeSearch,
    };
}

export function VehicleListDetails(props: VehicleListDetailsProps) {
    const { zoneId, locationId: locationIdProps, vehicleList } = props;
    console.log('VehicleListDetails', props);
    const isGlobal = !zoneId && !locationIdProps;
    const isIPermit = vehicleList?.serviceId === ServiceId.IPermit;
    const isTenantScope = isIPermit && (vehicleList.permits?.[0].scopes.length ?? 0) > 1;
    const isGetTenant = [ServiceId.IPark, ServiceId.IPermit].includes(vehicleList?.serviceId);

    const { services, getPermission, getService } = useContext(ServiceContext);
    const { permission: _permission } = useContext(AuthContext);
    const readOnly = _permission === AuthPermission.READ_ONLY;

    const isOnlyOneZone = vehicleList.zones.length === 1;

    const _initState: State = {
        ...initState,

        filter: {
            ...initState.filter,
            location: isGlobal ? undefined : ({ id: Number(locationIdProps) } as Location),
            zones: zoneId ? [{ id: Number(zoneId) } as Zone] : undefined,
        },
    };
    const [state, setState] = useState<State>(_initState);
    const filter = state.filter!;
    const [locationDefault, setLocationDefault] = useState<Location[]>([]);
    const [locations, setLocations] = useState<Location[]>(locationDefault);
    const [companiesDefault, setCompaniesDefault] = useState<Company[]>([]);
    const [companies, setCompanies] = useState<Company[]>(companiesDefault);
    const [regionsDefault, setRegionsDefault] = useState<Region[]>([]);
    const [regions, setRegions] = useState<Region[]>(regionsDefault);
    const [loadingList, setLoadingList] = useState<boolean>(true);
    const [filterLoading, setLoading] = useState({
        location: isGlobal,
        company: isGlobal,
        region: false,
        zones: !!locationIdProps,
        tenants: !!locationIdProps,
    });
    const isFilterLoading = Object.values(filterLoading).some((v) => v);

    const [pagingWhiteList, setPagingWhiteList] = useState<WhiteListFilterResponse>({
        page: 1,
        data: [],
        pageSize: 10,
        total: 0,
        totalPage: 0,
        totalVrn: 0,
    });
    const [searchParams, setSearchParams] = useSearchParams({});

    const locationId = searchParams.get('locationId');
    const companyId = searchParams.get('companyId');
    const zoneIds = searchParams.get('zoneIds');
    const region = searchParams.get('region');
    const _zoneIds = zoneIds?.split(',').map(Number);
    const tenantIds = searchParams.get('tenantIds');
    const _tenantIds = tenantIds?.split(',').map(Number);
    const permitDate = searchParams.get('permitDate');
    const _permitDate = permitDate?.split(',').map((item) => moment(item, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate());
    const permitDateTo = _permitDate ? moment(new Date(_permitDate[0])).startOf('days').toDate() : undefined;
    const permitDateForm = _permitDate ? moment(new Date(_permitDate[1])).endOf('days').toDate() : undefined;
    const search = searchParams.get('search');
    const page = searchParams.get('page');
    const servicesType = searchParams.get('servicesType');
    const VehicleListName = searchParams.get('VehicleListName');

    const handleChangeParams = (params: Partial<SearchParamsVLDetails>) => {
        const _searchParams: any = {
            servicesType,
            VehicleListName,
            locationId,
            companyId,
            region,
            zoneIds,
            tenantIds,
            permitDate,
            search,
            ...params,
        };
        Object.entries(_searchParams).forEach(([key, value]) => {
            const firstPage = key === 'page' && Number(value) === 1;
            if (!value || firstPage) delete (_searchParams as any)[key];
        });

        console.log({ _searchParams });

        setSearchParams(new URLSearchParams(_searchParams));
    };

    const [totalVrn, setTotalVrn] = useState(0);

    const [zones, setZones] = useState<Zone[]>([]);
    const [tenants, setTenants] = useState<Shop[]>([]);

    const setFilter = (filter: Partial<Filter>) => {
        setState((prev) => ({
            ...prev,
            page: 1,
            filter: { ...prev.filter, ...filter },
        }));
    };
    const getLocations = async () => {
        const _filterLocation: FilterLocation = {
            page: 1,
            pageSize: 10000,
            search: { fields: ['name'], value: '' },
            filter: {
                companyId: 0,
                regionId: 0,
                vehicleListId: vehicleList.id || 0,
            },
        };
        await locationController.getAll(_filterLocation).then(async (pagingLocation) => {
            const _location = pagingLocation.data.find((item) => item.id === Number(locationId));
            setLoading((prev) => ({ ...prev, location: false } as any));

            if (pagingLocation.data.length === 1 && !first.current) {
                handleChangeLocation({ location: pagingLocation.data[0] });
            } else {
                if (_location) {
                    const _zones = await zoneController.getZonesByVehicleList(_location!.id, vehicleList.id);
                    setZones(_zones);
                    const _zoneParams = _zones.filter((item) => _zoneIds?.includes(item.id));
                    const _tenant = await getTenants(_location.id);
                    setTenants(_tenant.data);
                    const _tenantParams = _tenant.data.filter((item) => _tenantIds?.includes(item.id));
                    setFilter({ location: _location, zones: _zoneParams, tenants: _tenantParams });
                }
            }
            first.current = true;
            setLocationDefault(pagingLocation.data);
            setLocations(pagingLocation.data);

            return pagingLocation;
        });
    };
    const getCompanies = async () => {
        const _filterLocation: FilterRequest<Company> = {
            page: 1,
            pageSize: 10000,
            search: { fields: ['displayName'], value: '' },
            filter: {
                vehicleListId: vehicleList.id || 0,
            },
        };
        await companyController.getAll(_filterLocation).then(async (res) => {
            if (companyId) {
                const _companyParams = res.data.find((item) => item.id === Number(companyId));
                setFilter({ company: _companyParams });
            }

            if (isIPermit) {
                const _company = res.data[0];
                setFilter({ company: _company });
                handleChangeParams({ companyId: _company.id });
                setLoading((prev) => ({ ...prev, company: false } as any));

                const pagingLocation = await locationController.getAll({
                    page: 1,
                    pageSize: 10,
                    search: { fields: ['name'], value: '' },
                    filter: { companyId: res.data[0].id, regionId: 0, vehicleListId: vehicleList.id },
                });

                if (pagingLocation.data.length === 1) {
                    handleChangeLocation({
                        location: pagingLocation.data[0],
                        companyProps: _company,
                    });
                } else {
                    setLoading((prev) => ({
                        ...prev,
                        location: false,
                        region: false,
                        tenants: false,
                        zones: false,
                    }));
                }
            } else {
                setLoading((prev) => ({ ...prev, company: false } as any));
            }
            setCompanies(res.data);
            setCompaniesDefault(res.data);

            return res;
        });
    };

    const getRegions = async () => {
        if (!filter.company?.id || !vehicleList.id) return;
        const _filter: FilterRequest<Region> = {
            page: 1,
            pageSize: 1000,
            search: { fields: ['name'], value: '' },
            filter: {
                companyId: filter.company?.id || 0,
                vehicleListId: vehicleList.id || 0,
            },
        };
        regionController.getAll(_filter).then((res) => {
            setLoading((prev) => ({ ...prev, region: false } as any));
            setRegions(res.data);
            setRegionsDefault(res.data);
            if (region) {
                const _region = filter.region || res.data.find((item) => item.id === Number(region));
                setFilter({ region: _region });
            }
            return res;
        });
    };

    const handleChangeLocation = async ({
        location,
        companyProps,
    }: {
        location?: Location;
        companyProps?: Company;
    }) => {
        if (!location) {
            handleChangeParams({ locationId: undefined, zoneIds: undefined, region: undefined });
            setFilter({ location, zones: [], region: undefined });
        } else {
            setLoadingList(true);
            setLoading((prev) => ({
                ...prev,
                zones: true,
                company: !companyProps,
                location: false,
                region: true,
                vehicleList: true,
                tenants: true,
            }));
            setFilter({ location });
            const companyId = location.companyId || location.region?.companyId;
            const _zones = await zoneController.getZonesByVehicleList(location.id, vehicleList.id);
            const zoneIdsParam = _zones.map((item: Zone) => encodeURIComponent(item.id)).join(',');
            const _zoneParams = _zones.filter((item) => _zoneIds?.includes(item.id));
            const _tenant = await getTenants(location.id, isTenantScope ? [_zones[0]?.id] : []);
            console.log(_tenant);
            const _tenantParams = isTenantScope
                ? [_tenant.data[0]].map((item: Shop) => encodeURIComponent(item.id)).join(',')
                : undefined;
            const company =
                companyProps || filter.company || (companyId && (await companyController.getById(companyId)));
            const region: Region = filter.region || (await regionController.getById(location.regionId));
            setZones(_zones);
            isGetTenant && setTenants(_tenant.data);

            handleChangeParams({
                locationId: location.id,
                zoneIds: zoneIdsParam,
                companyId: company.id,
                region: region.id,
                tenantIds: _tenantParams,
            });
            setFilter({
                zones: zoneIds ? _zoneParams : _zones,
                company,
                region,
                tenants: isTenantScope ? [_tenant.data[0]] : [],
            });
            setLoading((prev) => ({
                ...prev,
                zones: false,
                company: false,
                location: false,
                region: false,
                vehicleList: false,
                tenants: false,
            }));
        }
    };

    const first = useRef<boolean>(false);

    const handleChangeType = (type: SearchType) => {
        setState(
            (prev) =>
                ({
                    ...prev,
                    search: {
                        ...prev.search,
                        type,
                    },
                } as any)
        );
    };

    const _useSearch = useSearch({
        searchFilter: search ?? '',
        onChangeText: (text) => setState((prev) => ({ ...prev, page: 1, search: { ...prev.search, value: text } })),
    });

    const getPermitType = () => {
        const isPermitRecurring = (props.vehicleList?.permits?.[0].permitRecurring?.length ?? 0) > 0;
        const isIndefinite = isPermitRecurring
            ? props.vehicleList?.permits?.[0].permitRecurring?.[0].recurringType === RecurringType.indefinite
            : false;
        const isOnce = isPermitRecurring
            ? props.vehicleList?.permits?.[0].permitRecurring?.[0].recurringType === RecurringType.once
            : false;

        if (isPermitRecurring) {
            if (isIndefinite) {
                return PermitTypeVl.Indefinite;
            } else if (isOnce) {
                return PermitTypeVl.Once;
            } else {
                return PermitTypeVl.Temporary;
            }
        }
        return undefined;
    };

    const getWhiteList = (props?: Partial<any>) => {
        console.log(servicesType);

        setLoadingList(true);
        whiteListController
            .filter({
                page: 1,
                pageSize: 10,
                filter: {
                    companyId: Number(companyId) || 0,
                    locationId: Number(locationId) || 0,
                    regionId: Number(region) || 0,
                    zoneIds: _zoneIds || [],
                    from: permitDateTo ?? null,
                    to: permitDateForm ?? null,
                    vehicleListId: vehicleList.id,
                    tenantIds: _tenantIds || [],
                    permitTypeVl: getPermitType(),
                    serviceId: vehicleList.serviceId,
                    serviceType: Number(servicesType) || 0,
                },
                ...props,
                search: {
                    fields: [state.search.type.id],
                    value: props?.search?.value || search || '',
                },
            })
            .then((res) => {
                setPagingWhiteList(res);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() => setLoadingList(false));
    };

    const handleChangeStatus = async () => {
        vehicleListController
            .changeStatus(vehicleList.id)
            .then((res) => {
                props.onChangeActive(res.status);
                pushSuccess('Change status successfully.');
            })
            .catch((err) => {
                pushError('Change status fail');
            })
            .finally(() => {
                setPopUpConfirmActive(false);
            });
    };

    useEffect(() => {
        if (!isGlobal && locationIdProps && vehicleList.id) {
            zoneController.getZonesByVehicleList(Number(locationIdProps), vehicleList.id).then((res) => {
                setFilter({ zones: res });
                setZones(res);
                setLoading((prev) => ({ ...prev, zones: false }));
                !isGetTenant && setLoading((prev) => ({ ...prev, tenants: false }));

                const zoneIds = res.map((r) => r.id);
                isGetTenant &&
                    getTenants(Number(locationIdProps), zoneIds)
                        .then((pagination) => {
                            setFilter({ tenants: pagination.data });
                            setTenants(pagination.data);
                        })
                        .finally(() => {
                            setLoading((prev) => ({ ...prev, tenants: false }));
                        });
            });
        }
    }, [isGlobal, locationIdProps, vehicleList.id]);

    useEffect(() => {
        !isFilterLoading &&
            getWhiteList({ page: page || 1, search: { fields: [state.search.type.id], value: search } });
    }, [filter, state.search.type.id, page, search, isFilterLoading, permitDate]);

    useEffect(() => {
        vehicleList.id &&
            vehicleController
                .filter({
                    page: 1,
                    filter: {
                        vehicleListId: vehicleList.id,
                    },
                    pageSize: 1,
                    search: {
                        fields: ['name'],
                        value: '',
                    },
                })
                .then((res) => {
                    setTotalVrn(res.total);
                });
    }, [vehicleList.id]);

    const getTenants = async (locationId?: number, zoneIds?: number[]) => {
        return await tenantController.filter({
            page: 1,
            pageSize: 10000,
            filter: {
                locationId: locationId ?? 0,
                zoneIds: zoneIds ?? [],
                vehicleListId: props.vehicleList.id,
            },
        });
    };

    const permission = getPermission?.(vehicleList.serviceId);

    const [popUpConfirmActive, setPopUpConfirmActive] = useState(false);
    const isInternalPermit = ['Internal permit'].includes(getService?.(vehicleList.serviceId)?.name || '');
    useEffect(() => {
        if (isFilterLoading) return;
        const regionId = filter.region?.id ?? 0;
        const companyId = filter.company?.id ?? 0;
        if (!filter.company?.id && !filter.region?.id) {
            setLocations(locationDefault);
        } else {
            const _location = locationDefault.filter(
                (item) =>
                    (companyId === 0 || item.companyId === companyId) && (regionId === 0 || item.regionId === regionId)
            );
            setLocations(_location);
        }
    }, [filter.company?.id, filter.region?.id]);
    //set action view first time
    useEffect(() => {
        vehicleListController.viewAction({
            vehicleListId: props.vehicleList.id,
            action: VLLogAction['View permit whitelist'],
        });
        if (!isIPermit) {
            getLocations();
        }
        getCompanies();
    }, []);

    useEffect(() => {
        getRegions();
    }, [vehicleList.id, filter.company?.id]);

    return (
        <Box sx={{ px: '12px', pt: isGlobal ? 0 : 3 }}>
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
                <Stack spacing={1} flex={1}>
                    <Stack direction={'row'} alignItems="center" sx={{ height: 24.69 }}>
                        <TitleWithBackButton variant="h4" ml={0}>
                            Vehicle list: {vehicleList.name}
                        </TitleWithBackButton>
                        <Chip
                            size="small"
                            label={getService?.(vehicleList.serviceId)?.name}
                            sx={{ borderRadius: '16px !important', mx: 1.5 }}
                        />

                        <Box>
                            {[ServiceId.IPark, ServiceId.InternalPermit].includes(vehicleList.serviceId) && (
                                <Tooltip
                                    title={props.vehicleList.isDefault ? 'Unable to edit default vehicle list' : ''}
                                >
                                    <span>
                                        <LinkTo
                                            isDisable={props.vehicleList.isDefault}
                                            to={`${props.action?.onEdit(props.vehicleList)}`}
                                        >
                                            <IconButton
                                                color="primary"
                                                disabled={readOnly || props.vehicleList.isDefault}
                                            >
                                                <EditIcon3 />
                                            </IconButton>
                                        </LinkTo>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    </Stack>
                    <ZoneList zoneName={vehicleList.zoneName} scopeType={vehicleList.permits?.[0].scopes[0]?.scopeType} totalZone={vehicleList.totalZone}/>
                    {!isInternalPermit && <TenantList tenants={vehicleList.shops} />}
                </Stack>

                <Stack direction={'row'} alignItems="center" spacing={1}>
                    {permission?.changeActive && (
                        <Tooltip title={vehicleList.isDefault ? 'Cannot de-activate default vehicle list' : ''}>
                            <GFlexRow sx={{ '& label': { margin: 0 } }}>
                                <IOSSwitch
                                    checked={vehicleList?.status === 1}
                                    // disabled={changeStatusLoading}
                                    disabled={readOnly || vehicleList.isDefault}
                                    onChange={(e, check) => {
                                        if (isInternalPermit) {
                                            setPopUpConfirmActive(true);
                                        } else {
                                            handleChangeStatus();
                                        }
                                    }}
                                />
                            </GFlexRow>
                        </Tooltip>
                    )}
                    <PopUpWarning
                        open={popUpConfirmActive}
                        title="Confirm"
                        message="This change will take effect from tomorrow."
                        onClose={() => setPopUpConfirmActive(false)}
                        onConfirm={() => {
                            handleChangeStatus();
                        }}
                    />
                    {permission?.addPermit && (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: 200 }}
                            onClick={() => props.action?.onAddVRN(props.vehicleList)}
                        >
                            Add Permit
                        </Button>
                    )}
                    <LinkTo to={`${props.action?.onViewEventLog(props.vehicleList)}`}>
                        <Button
                            variant="outlinedGreen"
                            color="secondary"
                            sx={{
                                minWidth: 200,
                                background: color.success,
                                color: color.white,
                                '&:hover': {
                                    background: '#2E7D32',
                                    color: 'white',
                                },
                            }}
                        >
                            View logs
                        </Button>
                    </LinkTo>
                </Stack>
            </Stack>

            <Stack mt={3}>
                <FilterRoot
                    baseStyle={{ mt: 2 }}
                    rightTitleContent={
                        <Stack direction={'row'} spacing={2}>
                            <Stack>
                                <StyledDateRangePicker
                                    value={{
                                        startDate: _permitDate ? _permitDate[0] : undefined,
                                        endDate: _permitDate ? _permitDate[1] : undefined,
                                    }}
                                    onChange={(value) => {
                                        const dateParams = [value.startDate, value.endDate]
                                            .map((item: Date) => encodeURIComponent(item.toISOString()))
                                            .join(',');
                                        handleChangeParams({ permitDate: dateParams });
                                    }}
                                    initialSettings={{
                                        isInvalidDate: (date: Moment) => {
                                            return (
                                                moment(date).isBefore(
                                                    moment(vehicleList.firstWhiteListCreated ?? new Date()).startOf('d')
                                                ) || moment(date).isAfter()
                                            );
                                        },
                                    }}
                                />
                            </Stack>

                            <SearchField
                                searchTypes={[searchTypes[0]]}
                                onFocus={() => _useSearch.setSearchFocused(true)}
                                onBlur={() => _useSearch.setSearchFocused(false)}
                                value={_useSearch.searchValue || ''}
                                selectType={state.search.type}
                                onChangeType={handleChangeType}
                                onChange={(e) => {
                                    const _value = e.target.value;
                                    handleChangeParams({ search: _value });
                                    _useSearch.setSearchValue(_value);
                                    _useSearch.searchFocused && _useSearch.handleChangeSearch(_value);
                                }}
                            />
                        </Stack>
                    }
                    handleResetFilter={() => {
                        setState((prev) =>
                            isIPermit
                                ? {
                                      ...prev,
                                      page: 1,
                                      search: initState.search,
                                      filter: { ...prev.filter, from: undefined, to: undefined },
                                  }
                                : _initState
                        );
                        handleChangeParams({
                            companyId: undefined,
                            locationId: undefined,
                            page: undefined,
                            permitDate: undefined,
                            region: undefined,
                            search: undefined,
                            tenantIds: undefined,
                            zoneIds: undefined,
                        });
                    }}
                    filterChild={
                        !zoneId && (
                            <Grid item container xs={12} spacing={2}>
                                {isGlobal && (
                                    <>
                                        <Grid item xs={12}>
                                            <AutocompleteNoPagi
                                                pagingDefault={locationDefault}
                                                value={filter.location}
                                                paging={locations}
                                                hasNextPage={false}
                                                isNextPageLoading={true}
                                                loadNextPage={(res) => {
                                                    console.log(res);
                                                }}
                                                disabled={!isGlobal || isIPermit}
                                                onChange={(l) =>
                                                    handleChangeLocation({
                                                        location: l!,
                                                    })
                                                }
                                                loading={filterLoading.location}
                                                searchLoading={false}
                                                label="Location"
                                                readOnly={isIPermit}
                                                placeholder="All locations"
                                                getOptionLabel={(option) => option.name}
                                            />
                                        </Grid>

                                        <Grid item xs={isInternalPermit ? 3 : 6}>
                                            {/* <StyledAutocomplete
                                                {...companyAutocomplete}
                                                readOnly={isIPermit}
                                                label="Company"
                                                placeholder="All companies"
                                                getOptionLabel={(option) => option.displayName}
                                            /> */}
                                            <AutocompleteNoPagi
                                                pagingDefault={companiesDefault}
                                                value={filter.company}
                                                paging={companies}
                                                disabled={!isGlobal || !!filter.location}
                                                hasNextPage={false}
                                                isNextPageLoading={true}
                                                loadNextPage={(res) => {
                                                    console.log(res);
                                                }}
                                                onChange={(r) => {
                                                    setFilter({ company: r });
                                                    handleChangeParams({ companyId: r?.id });
                                                }}
                                                loading={filterLoading.company}
                                                searchLoading={false}
                                                readOnly={isIPermit}
                                                label="Company"
                                                placeholder="All companies"
                                                getOptionLabel={(option) => option.displayName}
                                            />
                                        </Grid>
                                        <Grid item xs={isInternalPermit ? 3 : 6}>
                                            {/* <StyledAutocomplete
                                                {...regionAutocomplete}
                                                readOnly={isIPermit || !!filter.location}
                                                label="Region"
                                                placeholder="All regions"
                                                getOptionLabel={(option) => option.name}
                                            /> */}
                                            <AutocompleteNoPagi
                                                pagingDefault={regionsDefault}
                                                value={filter.region}
                                                paging={regions}
                                                disabled={!isGlobal || !filter.company || isIPermit}
                                                hasNextPage={false}
                                                isNextPageLoading={true}
                                                loadNextPage={(res) => {
                                                    console.log(res);
                                                }}
                                                onChange={(r) => {
                                                    setFilter({ region: r });
                                                    handleChangeParams({ region: r?.id });
                                                }}
                                                loading={filterLoading.region}
                                                searchLoading={false}
                                                readOnly={isIPermit || !!filter.location}
                                                label="Region"
                                                placeholder="All regions"
                                                getOptionLabel={(option) => option.name}
                                            />
                                        </Grid>
                                    </>
                                )}

                                {!zoneId && (
                                    <Grid item xs={isInternalPermit ? 6 : isGlobal ? 6 : 12}>
                                        <IMultiSelectWithCheckBox
                                            limitTags={3}
                                            label={'Zone'}
                                            keyId={'id'}
                                            keyLabel={'publicName'}
                                            listData={zones}
                                            isDisable={
                                                (!filter.location || isIPermit || filterLoading.zones) && isGlobal
                                            }
                                            value={filter.zones ?? []}
                                            setFilter={(option) => {
                                                const zoneIdsParam = option
                                                    .map((item: Zone) => encodeURIComponent(item.id))
                                                    .join(',');
                                                handleChangeParams({ zoneIds: zoneIdsParam });
                                                setFilter({ zones: option });
                                            }}
                                            chipSize={isGlobal ? undefined : 300}
                                        />
                                    </Grid>
                                )}
                                {!isInternalPermit && (
                                    <Grid item xs={isGlobal ? 6 : 12}>
                                        <IMultiSelectWithCheckBox
                                            limitTags={isGlobal ? 1 : 3}
                                            label={'Tenant'}
                                            keyId={'id'}
                                            keyLabel={'tenantName'}
                                            listData={tenants}
                                            isDisable={
                                                (isGlobal ? !filter.location : false) ||
                                                filterLoading.tenants ||
                                                isIPermit
                                            }
                                            value={filter.tenants ?? []}
                                            setFilter={(option) => {
                                                const _tenant = option
                                                    .map((item: Shop) => encodeURIComponent(item.id))
                                                    .join(',');
                                                handleChangeParams({ tenantIds: _tenant });
                                                setFilter({ tenants: option });
                                            }}
                                            chipSize={isGlobal ? undefined : 300}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )
                    }
                />
            </Stack>

            <Stack mt={3} direction={'row'} justifyContent="space-between">
                <Typography variant="body1" component={'div'}>
                    Description:{' '}
                    <Typography component={'i'}>
                        {GetVehicleListDescription(vehicleList.permits?.[0], vehicleList.serviceId, isGlobal)}
                    </Typography>
                    {vehicleList.isExpired && (
                        <Chip
                            size="small"
                            sx={{
                                borderRadius: '16px',
                                height: 22,
                                fontSize: 12,
                                '&.MuiChip-root': {
                                    background: 'rgba(224, 27, 0, 0.1)',
                                    color: color.danger,
                                },
                                ml: 1,
                            }}
                            label={'Expired'}
                        />
                    )}
                </Typography>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                    {vehicleList.serviceId === ServiceId.InternalPermit && (
                        <Typography>
                            Total VRNs: {totalVrn || 0}{' '}
                            <Typography
                                color="primary.main"
                                component={'span'}
                                sx={{ cursor: 'pointer', display: 'initial' }}
                            >
                                (
                                <LinkTo to={`${props.action?.onViewVRN(props.vehicleList)}`}>
                                    <Typography
                                        color="primary.main"
                                        component={'span'}
                                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        // onClick={() => props.action?.onViewVRN(props.vehicleList)}
                                    >
                                        View permit schedules
                                    </Typography>
                                </LinkTo>
                                )
                            </Typography>
                        </Typography>
                    )}
                    <Typography color="success.main">Total permits: {pagingWhiteList.total || 0}</Typography>
                </Stack>
            </Stack>

            <LoadingWrap loading={loadingList} rows={pagingWhiteList.data}>
                <Box mt={2} mb={10}>
                    <Stack spacing={2}>
                        {pagingWhiteList.data.map((whiteList, index) => {
                            return (
                                <WhiteListItem
                                    whiteList={whiteList}
                                    vehicleList={props.vehicleList}
                                    key={whiteList.id}
                                />
                            );
                        })}
                    </Stack>

                    {pagingWhiteList.totalPage > 1 && (
                        <Stack mt={3} direction={'row'} justifyContent="center">
                            <MuiPagination
                                count={pagingWhiteList.totalPage || 1}
                                page={pagingWhiteList.page}
                                onChange={(_, page) => {
                                    handleChangeParams({ page: page });
                                    // setState((prev) => ({ ...prev, page: page }));
                                }}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Stack>
                    )}
                </Box>
            </LoadingWrap>
        </Box>
    );
}

export const VehicleListDetailPage = (props: VehicleListPageProps) => {
    const { path, locationId, zoneId } = useVehicleListParams();
    const navigate = useNavigate();

    return (
        <WrapVehicleList>
            {(vehicleList, handleChangeActive) => (
                <VehicleListDetails
                    action={
                        isLib
                            ? undefined
                            : {
                                  onAddVRN: () => navigate(`vrn/add`),
                                  onEdit: () => {
                                      return `/${path}/vehicle-list/edit/${vehicleList.id}`;
                                  },
                                  onViewVRN: () => {
                                      return `/${path}/vehicle-list/${vehicleList.id}/vrn`;
                                  },
                                  onBack: () => navigate(-1),
                                  onViewEventLog: () => {
                                      return `/${path}/vehicle-list/${vehicleList.id}/event-logs`;
                                  },
                              }
                    }
                    locationId={locationId}
                    vehicleList={vehicleList}
                    zoneId={zoneId}
                    onChangeActive={handleChangeActive}
                />
            )}
        </WrapVehicleList>
    );
};

type PropsChildren = {
    children(
        vehicleList: VehicleListWithZone,
        onChangeActive: (status: number) => any,
        locationId?: string,
        zoneId?: string
    ): JSX.Element;
};

export type WrapVehicleListComponentProps = VehicleListPageProps &
    PropsChildren & {
        vehicleListId: string;
    };

function WrapVehicleListChildren(props: WrapVehicleListComponentProps) {
    const { vehicleListId, zoneId, locationId } = props;
    const [vehicleList, setVehicleList] = useState<VehicleListWithZone>();

    useEffect(() => {
        vehicleListId &&
            vehicleListController.getById(Number(vehicleListId)).then((res) => {
                setVehicleList(res);
            });
    }, [vehicleListId]);

    const handleChangeActive = (status: number) => {
        setVehicleList((prev) => ({ ...prev, status: status } as VehicleListWithZone));
    };

    return !vehicleList ? (
        <Stack direction={'row'} justifyContent="center" pt={global ? 0 : 5}>
            <CircularProgress />
        </Stack>
    ) : (
        props.children(vehicleList, handleChangeActive, locationId, zoneId)
    );
}

export const useVehicleListParams = () => {
    const { vehicleListId, locationId, zoneId } = useParams();
    const path = zoneId ? `zone/${zoneId}` : locationId ? `location/${locationId}` : 'global';

    return {
        vehicleListId,
        locationId,
        zoneId,
        path,
    };
};

export function WrapVehicleList(props: VehicleListPageProps & PropsChildren) {
    const { vehicleListId, locationId, zoneId } = useVehicleListParams();

    if (!vehicleListId) return <></>;
    return <WrapVehicleListChildren {...props} locationId={locationId} zoneId={zoneId} vehicleListId={vehicleListId} />;
}

export function WrapVehicleListComponent(props: WrapVehicleListComponentProps) {
    const { vehicleListId } = props;

    if (!vehicleListId) return <></>;
    return <WrapVehicleListChildren {...props} />;
}

type State = Omit<FilterRequest<Filter>, 'search'> & {
    search: Search;
    serviceType?: number;
};

interface Search {
    type: SearchType;
    value: string;
}

interface SearchType {
    name: string;
    id: string;
}
interface Filter {
    company: Company;
    region: Region;
    location: Location;
    zones: Zone[];
    tenants: Shop[];
    from: Date;
    to: Date;
}

// Vehicle list
// Location

// Company
// Region
// Vrn
// Whitelist

// Company detail
// Region detail
// Zones
// Tenant
// Whitelist
