import { BaseHttpController } from 'ale-base-model';
import { AxiosInstance } from 'axios';
import { Pagination } from '../../models/filter/BaseFilter';
import { Shop } from '../../models/scope/Tenant';
import { Search } from '../vehicleList/WhiteListController';


export interface FilterDetail {
    vehicleListId?: number;
    locationId: number;
}
export interface FilterTenantProps {
    page: number;
    pageSize: number;
    search: Search;
    filter: Partial<FilterDetail>;
}

export type TenantFilter = Pick<FilterTenantProps, 'page' | 'pageSize'> & {
    filter: { locationId: number, vehicleListId?: number; zoneIds?: number[] };
};

export class TenantController extends BaseHttpController<Shop> {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'Shop', client);
    }

    async filter(props: TenantFilter): Promise<Pagination<Shop>> {
        const res = await this.doPost({ path: 'filterByVehicleList', body: props, config: {} });
        return res.data;
    }

    getById(id: number) {
        return this.doGet({ path: `${id}`, config: {} }).then((res) => res.data);
    }
}

export interface DeleteVrn {
    vrnNumber: string,
    vehicleListId: number
}
