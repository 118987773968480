/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Box, FormControl, FormControlLabel, Grid, IconButton, RadioGroup, Stack, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import RemoveIcon from '../../../../components/svg/RemoveIcon';
import color from '../../../../config/Colors';
import { LocationWithZones } from '../../../../models/scope/Location';
import { Shop } from '../../../../models/scope/Tenant';
import Zone from '../../../../models/scope/Zone';
import { DangerTooltip, GFlexColumn, GFlexRow } from '../../../../styles/style';
import {
    locationSRadio,
    SERVICE_TYPE_INTERNAL_PERMIT,
    SERVICE_TYPE_IPARK,
    tenantSRadio,
    TENANT_SCOPE_CUSTOM,
} from '../../constant';
import { isCompany } from '../../helper';
import { VehicleListUpsertContext } from '../../hooks/useVehicleListUpsert';
import { UnionScope } from '../model/UpsertVehicleList';
import MultiSelectPermitScopeZone from './MultiSelectPermitScopeZone';

type Props = {
    currentLocation: LocationWithZones;
    allLocation: LocationWithZones[];
    handleAddLocation: (location: LocationWithZones) => void;
    handleRemoveLocation: (location: LocationWithZones) => void;
    idParentCompany: number;
    index: number;
    company: UnionScope;
    locationSelect: LocationWithZones;
    isShowGlobalItem: boolean;
};

export default function LocationAndZoneSelectItem({
    currentLocation,
    handleRemoveLocation,
    idParentCompany,
    index,
    company,
    locationSelect,
    isShowGlobalItem,
}: Props) {
    const { selectionState, setPartialSelectionState, vehicleListSelection, methods, setPartialState } =
        useContext(VehicleListUpsertContext);
    const { companySelects } = selectionState;
    const { getLocationScope, setLocationScope, getTenantScope, setTenantScope } = vehicleListSelection;
    const { watch } = methods;

    const { serviceType, isAllowTenant } = watch();
    const isInternalPermit = serviceType === SERVICE_TYPE_INTERNAL_PERMIT;

    const getAllZone = () => {
        if (isCompany(company)) {
            const location = company.locations!.find((l) => l.id === currentLocation.id);
            return location!.zones! ?? [];
        } else return [];
    };

    const getAllTenants = () => {
        if (isCompany(company)) {
            const location = company.locations!.find((l) => l.id === currentLocation.id);
            return location!.shops! ?? [];
        } else return [];
    };

    const allZones = getAllZone();
    const allTenants = getAllTenants();

    const handleChangeZoneToParent = (zones: Zone[]) => {
        //add to parent
        const companySelectsParent = _.cloneDeep(companySelects);
        const parentIndex = companySelectsParent.findIndex((item) => {
            if (isCompany(item)) return item.id === idParentCompany;
        });
        if (parentIndex >= 0) {
            const union = _.cloneDeep(companySelectsParent[parentIndex]);
            if (isCompany(union)) {
                const locationSelectsParent = _.cloneDeep(union.locations!);
                const locationIndex = locationSelectsParent.findIndex((item) => item.id === currentLocation.id);
                if (locationIndex >= 0) {
                    const currentZoneSelect = _.cloneDeep(locationSelectsParent[locationIndex]);
                    currentZoneSelect.zones = zones;
                    //set to parent clone location
                    locationSelectsParent[locationIndex] = currentZoneSelect;
                    //set to parent clone company
                    union.locations = locationSelectsParent;
                    companySelectsParent[parentIndex] = union;
                    //set clone company to parent
                    setPartialSelectionState({ companySelects: companySelectsParent });
                }
            }
        }
    };

    console.log(allTenants);
    

    const handleChangeTenantToParent = (tenants: Shop[]) => {
        console.log(tenants);
        
        //add to parent
        const companySelectsParent = _.cloneDeep(companySelects);
        const parentIndex = companySelectsParent.findIndex((item) => {
            if (isCompany(item)) return item.id === idParentCompany;
        });
        if (parentIndex >= 0) {
            const union = _.cloneDeep(companySelectsParent[parentIndex]);
            if (isCompany(union)) {
                const locationSelectsParent = _.cloneDeep(union.locations!);
                const locationIndex = locationSelectsParent.findIndex((item) => item.id === currentLocation.id);
                if (locationIndex >= 0) {
                    const currentZoneSelect = _.cloneDeep(locationSelectsParent[locationIndex]);
                    currentZoneSelect.shops = tenants;
                    //set to parent clone location
                    locationSelectsParent[locationIndex] = currentZoneSelect;
                    //set to parent clone company
                    union.locations = locationSelectsParent;
                    companySelectsParent[parentIndex] = union;
                    //set clone company to parent
                    setPartialSelectionState({ companySelects: companySelectsParent });
                }
            }
        }
    };

    //validation tenant scope
    useEffect(() => {
        let isAllTenantScopeValid = true;
        companySelects.map((c) => {
            if (isCompany(c)) {
                c.locations?.map((l) => {
                    if (l.tenantScope && l.tenantScope === TENANT_SCOPE_CUSTOM && l.shops && l.shops.length === 0) {
                        isAllTenantScopeValid = false;
                    }
                });
            }
        });
        setPartialState({ isScopeValid: isAllTenantScopeValid });
    }, [companySelects]);

    return (
        <Grid item container alignItems={'stretch'} py={1}>
            <GFlexRow
                item
                xs={12}
                borderRadius={'5px'}
                sx={{
                    transition: 'all 0.2s',
                    py: 1.5,
                    '&:hover': {
                        background: color.grey100,
                    },
                }}
            >
                <GFlexRow item xs={6} justifyContent="space-between">
                    <GFlexRow item xs={7} justifyContent="start" height={'100%'}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                pl: 2,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {index}. {currentLocation.name}
                        </Typography>
                    </GFlexRow>

                    <Stack justifyContent={'space-between'} gap={1.5}>
                        {/* All zones custom */}
                        <DangerTooltip
                            title={getAllZone().length === 0 ? 'There is no zone in this location' : ''}
                            placement="top"
                            arrow
                        >
                            <FormControl sx={{ width: '210px' }}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={getLocationScope(idParentCompany, currentLocation.id)}
                                    sx={{
                                        '& input': {},
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {locationSRadio.map((item, index) => {
                                        const isDisabled = getAllZone().length === 0;
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                value={item.id}
                                                onClick={() => {
                                                    if (isDisabled) {
                                                    } else
                                                        setLocationScope(idParentCompany, currentLocation.id, item.id);
                                                }}
                                                disabled={isDisabled}
                                                control={<Radio />}
                                                label={<Typography>{item.name}</Typography>}
                                                sx={{ justifyContent: 'start', minHeight: '40px' }}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </DangerTooltip>

                        {/* All tenants custom */}
                        {serviceType === SERVICE_TYPE_IPARK && !isAllowTenant && (
                            <DangerTooltip
                                title={getAllTenants().length === 0 ? 'There is no tenant in this location' : ''}
                                placement="top"
                                arrow
                            >
                                <FormControl sx={{ width: '210px' }}>
                                    {
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={getTenantScope(idParentCompany, currentLocation.id)}
                                            sx={{
                                                '& input': {},
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            {tenantSRadio.map((item, index) => {
                                                const isDisable = getAllTenants().length === 0;
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={item.id}
                                                        onClick={() => {
                                                            if (isDisable) {
                                                            } else {
                                                                setTenantScope(
                                                                    idParentCompany,
                                                                    currentLocation.id,
                                                                    item.id
                                                                );
                                                                // handleChangeTenantRadio(item.id);
                                                            }
                                                        }}
                                                        disabled={isDisable}
                                                        control={<Radio />}
                                                        label={<Typography>{item.name}</Typography>}
                                                        sx={{ justifyContent: 'start', minHeight: '40px' }}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    }
                                </FormControl>
                            </DangerTooltip>
                        )}
                    </Stack>
                </GFlexRow>
                <GFlexColumn item xs={6} gap={2}>
                    <GFlexRow container justifyContent="end" gap={2}>
                        <GFlexColumn item xs={9} gap={1.5}>
                            {/* Zones multiselect */}
                            {allZones && allZones.length === 0 ? (
                                <Box height={'40px'}></Box>
                            ) : (
                                <MultiSelectPermitScopeZone
                                    listData={allZones}
                                    keyId={'id'}
                                    label={'Zone(s)'}
                                    keyLabel={'name'}
                                    value={locationSelect.zones!}
                                    setFilter={(e) => handleChangeZoneToParent(e)}
                                    isDisable={allZones.length === 0}
                                    isSelectAllZone={getLocationScope(idParentCompany, currentLocation.id) === 2}
                                />
                            )}
                            {/* Tenants multiselect */}
                            {!isAllowTenant &&
                                (allTenants && allTenants.length > 0 && serviceType === SERVICE_TYPE_IPARK ? (
                                    <MultiSelectPermitScopeZone
                                        listData={allTenants}
                                        keyId={'id'}
                                        label={'Tenants'}
                                        keyLabel={'tenantName'}
                                        placeHolder={'All tenants'}
                                        value={locationSelect.shops!}
                                        setFilter={(e) => handleChangeTenantToParent(e)}
                                        isDisable={allTenants.length === 0}
                                        isSelectAllZone={getTenantScope(idParentCompany, currentLocation.id) === 2}
                                    />
                                ) : (
                                    !isInternalPermit && <Box height={'40px'}></Box>
                                ))}
                        </GFlexColumn>
                        <Grid item width={'65px'}>
                            {isShowGlobalItem && (
                                <Grid item width={'65px'}>
                                    <Typography
                                        sx={{
                                            color: color.danger,
                                            cursor: 'pointer',
                                            p: '6px',
                                        }}
                                        onClick={() => handleRemoveLocation(currentLocation)}
                                        component={'div'}
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent={'center'}
                                    >
                                        <DangerTooltip title={'Remove location'} placement="top">
                                            <IconButton
                                                aria-label="fingerprint"
                                                color="error"
                                                sx={{
                                                    backgroundColor: color.grey200,
                                                    '&:hover': {
                                                        backgroundColor: color.grey200,
                                                    },
                                                    border: `2px solid ${color.grey100}`,
                                                    width: '32px',
                                                    height: '32px',
                                                    padding: '4px',
                                                }}
                                            >
                                                <RemoveIcon width={'30px'} height={'30px'} style={{ color: 'red' }} />
                                            </IconButton>
                                        </DangerTooltip>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </GFlexRow>
                </GFlexColumn>
            </GFlexRow>
        </Grid>
    );
}
