import { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function CloseIcon(props: Props) {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.98083 6.25796L12.8743 1.36447C13.3603 0.878508 14.1496 0.878508 14.6355 1.36447C15.1215 1.85044 15.1215 2.63971 14.6355 3.12568L9.74204 8.01917L14.6355 12.9127C15.1113 13.3873 15.1113 14.1597 14.6355 14.6355C14.1597 15.1113 13.3873 15.1113 12.9127 14.6355L8.01917 9.74204L3.12568 14.6355C2.63971 15.1215 1.85044 15.1215 1.36447 14.6355C0.878508 14.1496 0.878508 13.3603 1.36447 12.8743L6.25796 7.98083L1.36447 3.08734C0.888656 2.61265 0.888656 1.84029 1.36447 1.36447C1.84029 0.888656 2.61265 0.888656 3.08734 1.36447L7.98083 6.25796Z"
                fill="#85858A"
            />
        </svg>
    );
}
